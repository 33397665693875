var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-sheet',{staticClass:"pa-5"},[_c('h1',{staticClass:"display-1"},[_vm._v("Vans")]),_c('v-row',{staticClass:"mt-5"},[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4","lg":"3"}},[_c('v-select',{attrs:{"items":_vm.items,"label":"Status","outlined":"","hide-details":"","dense":""},model:{value:(_vm.vanStatus),callback:function ($$v) {_vm.vanStatus=$$v},expression:"vanStatus"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4","lg":"6"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","outlined":"","dense":"","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4","lg":"3"}},[_c('v-btn',{attrs:{"color":"primary","light":"","block":""},on:{"click":function($event){return _vm.toggleNewVan()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-plus-circle ")]),_vm._v(" Ajouter Van ")],1)],1)],1),_c('div',{staticClass:"mt-5"},[_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.vans,"loading":_vm.tableLoader,"search":_vm.search,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.brandModel",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-row justify-start align-center py-3 ml-5"},[_c('v-icon',{staticClass:"mr-3",attrs:{"x-large":""}},[_vm._v("mdi-van-passenger")]),_c('span',[_vm._v(" "+_vm._s(item.brandModel)+" ")])],1)]}},{key:"item.upcomingTrip",fn:function(ref){
var item = ref.item;
return [(Object.keys(item.upcomingTrip).length === 0)?_c('div',[_vm._v(" - ")]):_c('div',{staticClass:"d-flex flex-column align-center justify-center py-3 my-3",staticStyle:{"background-color":"#D2EBD3"}},[_c('p',{staticClass:"my-0"},[_vm._v(_vm._s(_vm.getDate(item.upcomingTrip.date)))]),_c('p',{staticClass:"my-0"},[_vm._v(_vm._s(_vm.getTime(item.upcomingTrip.time)))]),_c('p',{staticClass:"my-0"},[_vm._v(_vm._s(item.upcomingTrip.customerName))]),_c('p',{staticClass:"my-0"},[_vm._v(_vm._s(item.upcomingTrip.address))])])]}},{key:"item.assignedDrivers",fn:function(ref){
var item = ref.item;
return [(item.assignedDrivers.length > 0)?_c('div',{staticClass:"d-flex flex-column align-center justify-center"},_vm._l((item.assignedDrivers),function(van){return _c('p',{key:van,staticClass:"my-0"},[_vm._v(_vm._s(van))])}),0):_c('div',[_vm._v(" - ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-row align-center justify-center"},[_c('v-btn',{attrs:{"text":"","icon":"","color":"grey"},on:{"click":function($event){return _vm.goToVan(item.id)}}},[_c('v-icon',[_vm._v("mdi-eye")])],1)],1)]}}],null,true)})],1),_c('v-dialog',{attrs:{"persistent":"","scrollable":"","width":"720"},model:{value:(_vm.newVanDialog),callback:function ($$v) {_vm.newVanDialog=$$v},expression:"newVanDialog"}},[_c('van-form',{attrs:{"toggle":_vm.toggleNewVan,"updateVanArray":_vm.updateVanArray}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }