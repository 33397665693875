<template>
  <v-sheet class="pa-5">
    <h1 class="display-1">Vans</h1>
    <v-row class="mt-5">
      <v-col cols="12" sm="6" md="4" lg="3">
        <v-select
          v-model="vanStatus"
          :items="items"
          label="Status"
          outlined
          hide-details
          dense
        >
        </v-select>
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="6">
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          outlined
          dense
          single-line
          hide-details
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="3">
        <v-btn color="primary" light block @click="toggleNewVan()">
          <v-icon left>
            mdi-plus-circle
          </v-icon>
          Ajouter Van
        </v-btn>
      </v-col>
    </v-row>
    <div class="mt-5">
      <v-data-table
        :headers="headers"
        :items="vans"
        :loading="tableLoader"
        :search="search"
        hide-default-footer
        class="elevation-0"
      >
        <template v-slot:[`item.brandModel`]="{ item }">
          <div class="d-flex flex-row justify-start align-center py-3 ml-5">
            <v-icon x-large class="mr-3">mdi-van-passenger</v-icon>
            <span>
              {{ item.brandModel }}
            </span>
          </div>
        </template>
        <template v-slot:[`item.upcomingTrip`]="{ item }">
          <div v-if="Object.keys(item.upcomingTrip).length === 0">
            -
          </div>
          <div class="d-flex flex-column align-center justify-center py-3 my-3" style="background-color: #D2EBD3" v-else>
            <p class="my-0">{{ getDate(item.upcomingTrip.date) }}</p>
            <p class="my-0">{{ getTime(item.upcomingTrip.time) }}</p>
            <p class="my-0">{{ item.upcomingTrip.customerName }}</p>
            <p class="my-0">{{ item.upcomingTrip.address }}</p>
          </div>
          
        </template>
        <template v-slot:[`item.assignedDrivers`]="{ item }">
          <div class="d-flex flex-column align-center justify-center" v-if="item.assignedDrivers.length > 0">
            <p v-for="van in item.assignedDrivers" :key="van" class="my-0">{{ van }}</p>
  
          </div>
          <div v-else>
            -
          </div>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <div class="d-flex flex-row align-center justify-center">
            <v-btn text icon color="grey" @click="goToVan(item.id)">
              <v-icon>mdi-eye</v-icon>
            </v-btn>
            <!-- <v-btn text icon color="accent">
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
            <v-btn text icon color="error">
              <v-icon>mdi-delete</v-icon>
            </v-btn> -->
          </div>
        </template>
      </v-data-table>
    </div>
    <v-dialog v-model="newVanDialog" persistent scrollable width="720">
      <van-form :toggle="toggleNewVan" :updateVanArray="updateVanArray" />
    </v-dialog>
  </v-sheet>
</template>

<script>


import moment from 'moment'

export default {

  components: { VanForm: () => import('../../components/van/VanForm.vue') },

  data: () => ({
    tableLoader: true,
    newVanDialog: false,
    items: ["All", "Active", "Disable"],
    vanStatus: "All",
    search: "",
    headers: [
      {
        text: "Brand/Model",
        align: "center",
        value: "brandModel",
      },
      { text: "Van ID", align: "center", value: "customId" },
      { text: "Registration Number", align: "center", value: "registrationNumber" },
      { text: "Next Trip", align: "center", value: "upcomingTrip" },
      { text: "Drivers", align: "center", value: "assignedDrivers" },
      { text: "Actions", align: "center", value: "actions" },
    ],
    vans: []
  }),

  watch: {
    async vanStatus(val) {
      if(val == 'All') {
        this.vans = await this.$store.dispatch('van/getVans')
      } else {
        let vans = await this.$store.dispatch('van/getVansByStatus', { status: val })
        if(vans.length > 0) {
          this.vans = vans
        } else {
          this.vans = []
        }
      }
    }
  },

  async created() {
    this.vans = await this.$store.dispatch('van/getVans')
    this.tableLoader = false
  },

  methods: {

    updateVanArray(payload) {
      console.log(payload)
      this.vans.push(payload)
    },

    toggleNewVan() {
      this.newVanDialog = !this.newVanDialog
    },

    goToVan(id) {
      this.$router.push({
        name: 'Manager-Vans-Single',
        params: { id: id }
      })
    },

    getDate(date) {
      return moment(date).locale('fr').format('dddd D MMMM YYYY')
    },

    getTime(time) {
      let tmpVar = time.split('-')
      let start = `${moment(`2021-07-30 ${tmpVar[0]}`).locale('fr').format('HH')}h${moment(`2021-07-30 ${tmpVar[0]}`).locale('fr').format('mm')}`
      let end = `${moment(`2021-07-30 ${tmpVar[1]}`).locale('fr').format('HH')}h${moment(`2021-07-30 ${tmpVar[1]}`).locale('fr').format('mm')}`
      return `${start} - ${end}`
    }
  }

}
</script>

<style>

</style>